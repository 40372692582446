.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.Container{
  overflow-y: scroll;
  scroll-behavior: smooth;
 
} */

html {
  scroll-behavior: smooth;
}

#대전광역시{
  position: absolute;
  top: 45%;
  left: 15%;
  color:white;
}
#충청북도{
  position: absolute;
  top: 35%;
  left: 45%;
  color:white;
}
#서울특별시{
  position: absolute;
  top: 25%;
  left: 30%;
  color:white;
}
#경상북도{
  position: absolute;
  top: 42%;
  right: 15%;
  color:white;
}
#광주광역시{
  position: absolute;
  top: 68%;
  left: 10%;
  color:white;
}
#부산광역시{
  position: absolute;
  top: 69%;
  right: 5%;
  color:white;
}
#강원도{
  position: absolute;
  top: 15%;
  right: 25%;
  color:white;
}
#전라남도{
  position: absolute;
  top: 75%;
  left: 15%;
  color:white;
}
#세종특별자치시{
  position: absolute;
  top: 41%;
  left: 20%;
  color:white;
}
#충청남도{
  position: absolute;
  top: 38%;
  left: 15%;
  color:white;
}
#대구광역시{
  position: absolute;
  top: 55%;
  right: 12%;
  color:white;
}
#울산광역시{
  position: absolute;
  top: 62%;
  right: 3%;
  color:white;
}
#경상남도{
  position: absolute;
  top: 65%;
  right: 30%;
  color:white;
}
#제주특별자치도{
  position: absolute;
  top: 93%;
  left: 10%;
  color:white;
}
#인천광역시{
  position: absolute;
  top: 25%;
  left: 1%;
  color:white;
}
#전라북도{
  position: absolute;
  top: 58%;
  left: 25%;
  color:white;
}
#경기도{
  position: absolute;
  top: 20%;
  left: 25%;
  color:white;
 
}
button{
  border:none;
  border-radius: 30px;
  padding: 1px 5px;
  font-size: medium;
}









#fake{
  
  color:white;
}